export const DataModel = {
    channelIsEnabled: true,
    alternates: {},
    liveCams: {},
    nextPromo: {},
    idTimes: [
        { min: 59, sec: 45, videoIsFull: false }
    ],
    refreshTimes: [],
    prevLiveCams: {
        prim:{
            srcID: null,
            srcType: null,
            srcUrl: null
        },
        suba:{
            srcID: null,
            srcType: null,
            srcUrl: null
        },
        subb:{
            srcID: null,
            srcType: null,
            srcUrl: null
        },
        subc:{
            srcID: null,
            srcType: null,
            srcUrl: null
        }
    },
    inBuffer: [],
    inCurrent: [],
    newCams: [],
    newsObj: {},
    newsArr: [],
    newsKey: 0,
    tock: 0,
    minute: 0, 
    idPlus15: {
      min: 0,
      sec: 0
    },
    cameras: {},
    camerasArr: [],
    buffer: [],
    screenLocks: {
        prim: false,
        suba: false,
        subb: false,
        subc: false
    },
    screenMutes: {
        prim: false,
        suba: true,
        subb: true,
        subc: true
    },
    cams: []

}