export const Environment = {
    firebaseConfig: {
        apiKey: "AIzaSyDR4Wz-u4ne73jS4YJ9Q_hTC-40qiUEbCA",
        authDomain: "sh-railcam-tour.firebaseapp.com",
        projectId: "sh-railcam-tour",
        storageBucket: "sh-railcam-tour.appspot.com",
        messagingSenderId: "277445216183",
        appId: "1:277445216183:web:e20d1414fb212e04128a32"
    },
    fetchUrl: 'https://us-central1-sh-railcam-tour.cloudfunctions.net/getMotionCams'  
}
