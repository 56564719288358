import { initializeApp } from 'firebase/app'
import {
    getFirestore, onSnapshot, doc, getDoc, collection, query, where
  } from 'firebase/firestore'
import { DataModel } from './DataModel.js'
import { Environment } from './environment'


/**
 *                            Load Data Model
 */
const dm = DataModel;
let playerPrim = null;
let playerSuba = null;
let playerSubb = null;
let playerSubc = null;

/**
 *                    Set Document Object References
 */
//const vtPrim = document.querySelector('#vt-prim');   //getElementById("vt-prim");
const vsPrim = document.getElementById("vs-prim");
const vtSuba = document.getElementById("vt-suba");
const vsSuba = document.getElementById("vs-suba");
const vtSubb = document.getElementById("vt-subb");
const vsSubb = document.getElementById("vs-subb");
const vtSubc = document.getElementById("vt-subc");
const vsSubc = document.getElementById("vs-subc");
const news   = document.getElementById("newstext");
const tiPrime = document.getElementById("h1-prim");
const tiSuba = document.getElementById("h1-suba");
const tiSubb = document.getElementById("h1-subb");
const tiSubc = document.getElementById("h1-subc");


//CSS animation 
const animateCSS = (element, animation, prefix = 'animate__') => {
    // We create a Promise and return it
    new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`;
      const node = document.querySelector(element);
      node.classList.add(`${prefix}animated`, animationName);
      // When the animation ends, we clean the classes and resolve the Promise
      function handleAnimationEnd(event) {
        event.stopPropagation();
        node.classList.remove(`${prefix}animated`, animationName);
        resolve('Animation ended');
      }
      node.addEventListener('animationend', handleAnimationEnd, {once: true, passive: true});
    });
  }

/**
 *                      Link Database
 */
window.env = Environment;
const firebaseConfig = env.firebaseConfig;
initializeApp(firebaseConfig);
const db = getFirestore();




/**
 * 
 *                            Define Functions
 *  
 */


async function startClockLoop() {  
    /*   *   *   *   *   *   *   *   *   *   *  *  *  *
     *                                                *
     * Begin a 60 sec master clock for loop control   *
     *                                                *
     *   *   *   *   *   *   *   *   *   *   *  *  *  */
  
    setInterval( async ()=> {
      let now
      //Reset clock to 0 every 1 min (& increment minute)
      if(dm.tock==60) {
        dm.tock = 0
        fetchUpdate()
      }
      //Events below to fire on specific intervals (Modulas % determines multiples)
        
      //Every 20 sec --> 
      if(dm.tock%20==0) {
        //Change news text...
        if(dm.newsArr.length) {
          if(dm.newsKey >= dm.newsArr.length) {
            dm.newsKey = 0;
          }
          //Show Priority message only if activated
          if(dm.newsObj.Priority.isActive==true) {
            news.innerHTML = dm.newsObj.Priority.text
          //Otherwise show next message in the rotation  
          } else {
            outputNews();
            dm.newsKey++
          }
          
        }
      }

      //Every 1 sec advance clock 
      dm.tock++;
      
      //Test for time triggers
      now = getTime();
      dm.refreshTimes.forEach( (rt)=>{
        if(now.min==rt.min && now.sec==rt.sec) {
            //Refreshes the browser
            location.reload();
        }
      })
      dm.idTimes.forEach(async (idt) => {
        if(now.min==idt.min && now.sec==idt.sec) {
            //Ignore if empty
            //if(Object.entries(dm.nextPromo).length === 0 ) { return }
            console.log("Subc playing ", dm.nextPromo.srcID)  
            playPromo()


          
        }       
      })
    }, 1000);
    /*  END OF CLOCK LOOP   */
}



/**
 *    Data-related functions
 */


async function fetchControlsFlags() {
    const flagsSnapshot = onSnapshot(doc(db, "Controls", "Flags"), (querySnapshot) => {  
        let dataSet = querySnapshot.data()
        let wasOutput = false; //Resets when screen updates
        console.log("fetchControls() ", dataSet)
        dm.channelIsEnabled = dataSet.channelIsEnabled
        dm.alternates = dataSet.alternates 
        dm.updateUrl = dataSet.updateUrl 
        dm.liveCams = dataSet.liveCams
        dm.nextPromo = dataSet.nextPromo
        if(dm.channelIsEnabled===false) {
            disableScreens()
            return
        }
        updateScreens()
    })
}


async function fetchUpdate() {
    //runs server function updateMotionStatus which clears stale motion eventse
    const myHeaders = new Headers({
        'Content-Type': 'application/json'
        
      });
      const response = await fetch(dm.updateUrl, { 
        method:'GET', 
        headers: myHeaders,
        mode: 'no-cors'
      })
      console.log("fetchUpdate()", response)
}


async function fetchControlsTimers() {
    const timersSnapshot = onSnapshot(doc(db, "Controls", "Timers"), (querySnapshot) => {
        let dataSet = querySnapshot.data()
        dm.idTimes = dataSet.idTimes
        /* DATA FORMAT
        idTimes [
          {min:14 sec:50 videoIsFull:false },
          {min:44 sec:50 videoIsFull:false },
        ]
        */
        dm.refreshTimes = dataSet.refreshTimes
        /* DATA FORMAT
        refreshTimes [
          { min:14 sec:50 },
          { min:44 sec:50 },
        ]
       */
      console.log("fetchControlsTimers() idTimes", dm.idTimes)
    })
}

async function fetchNews() {
    const newsSnapshot = onSnapshot(doc(db, "News", "All"), (querySnapshot) => {
        let dataSet = querySnapshot.data()
        console.log("fetchNews()")
        let sdt, edt, obj, arr=[], i, keys = Object.keys(dataSet), now = new Date(), skip
        for(i=0; i<keys.length; i++) {
            obj = dataSet[keys[i]]
            skip = false
            if(keys[i]=="Priority" || obj.isActive==false) { 
                skip = true
            }           
            if(obj.isDateControlled==true) {
                sdt = new Date(obj.start.toDate())
                edt = new Date(obj.end.toDate())
                if(sdt.getTime() > now.getTime() || now.getTime() > edt.getTime()) {
                    console.log('Skipping not current message.')
                    skip = true;
                }
            }
            if(!skip) {
                arr.push(obj)
            }
        }
        dm.newsObj = dataSet
        dm.newsArr = arr
    })
}

function initApp() {
    fetchControlsFlags()
    fetchControlsTimers()
    fetchNews()
    startClockLoop()
    //fetchMotion()
}


function getTime() { 
    var date = new Date(); 
    var now = { 
        month: (date.getMonth()+1),
        date: date.getDate(),
        hour: date.getHours(), 
        min: date.getMinutes(), 
        sec: date.getSeconds() 
    }
    if(now.month > 12) { now.month = 1; }
    return now; 
} 

/**
 *    View-related Functions
 */



function disableScreens() {
    /**
     *  Conditionally run to stop all streams and show posters.
     */
    console.log("disableScreens()")
}


async function updatePrim(isMuted=false) {
    //initiate if new
    if(playerPrim == null) {
        const optionsPrim = {
            autoplay: true,
            controls: false,
            preload: "auto",
            fluid: true,
            loadingSpinner: false,
            muted: false,
            aspectRatio: "16:9",
            techOrder: ["html5", "youtube"]
        }
        playerPrim = videojs("vt-prim", optionsPrim, function onPlayerReady() {
            this.on('ended', function() {
                this.play();
            });
            this.src({ 
                type: dm.liveCams.prim.srcType,
                src: dm.liveCams.prim.srcUrl 
            });
            console.log("Primary playing ", dm.liveCams.prim.srcID)
            dm.prevLiveCams.prim.srcID = dm.liveCams.prim.srcID
            dm.prevLiveCams.prim.srcType = dm.liveCams.prim.srcType
            dm.prevLiveCams.prim.srcUrl = dm.liveCams.prim.srcUrl
            this.play();
        }); 
    //Otherwise just change source if its different
    } else if(dm.liveCams.prim.srcID != dm.prevLiveCams.prim.srcID) {
        playerPrim.src({ 
            type: dm.liveCams.prim.srcType,
            src: dm.liveCams.prim.srcUrl 
        })
        playerPrim.muted(isMuted)
        playerPrim.play()
        console.log("Primary playing ", dm.liveCams.prim.srcID)
        tiPrime.innerHTML = dm.liveCams.prim.srcName
        tiPrime.classList.add("animate-textfade")
        dm.prevLiveCams.prim.srcID = dm.liveCams.prim.srcID
        dm.prevLiveCams.prim.srcType = dm.liveCams.prim.srcType
        dm.prevLiveCams.prim.srcUrl = dm.liveCams.prim.srcUrl
        setTimeout(()=>tiPrime.classList.remove("animate-textfade"),8000)
        return true
    }
    return false
}

async function updateSuba(isMuted=true) {
    //initiate if new
    if(playerSuba == null) {
        const optionsSuba = {
            autoplay: true,
            controls: false,
            preload: "auto",
            fluid: true,
            loadingSpinner: false,
            muted: true,
            aspectRatio: "16:9",
            techOrder: ["html5", "youtube"]
        }
        playerSuba = videojs("vt-suba", optionsSuba, function onPlayerReady() {
            this.on('ended', function() {
                this.src({
                    type: dm.liveCams.suba.srcType,
                    src: dm.liveCams.suba.srcUrl
                })
                this.play();
            });
            this.src({ 
                type: dm.liveCams.suba.srcType,
                src: dm.liveCams.suba.srcUrl 
            });
            console.log("Suba playing ", dm.liveCams.suba.srcID)
            dm.prevLiveCams.suba.srcID = dm.liveCams.suba.srcID
            dm.prevLiveCams.suba.srcType = dm.liveCams.suba.srcType
            dm.prevLiveCams.suba.srcUrl = dm.liveCams.suba.srcUrl
            this.play();
        }); 
    //Otherwise just change source if its different
    } else if(dm.liveCams.suba.srcID != dm.prevLiveCams.suba.srcID) {
        playerSuba.src({ 
            type: dm.liveCams.suba.srcType,
            src: dm.liveCams.suba.srcUrl 
        })
        playerSuba.muted(isMuted)
        playerSuba.play()
        console.log("Suba playing ", dm.liveCams.suba.srcID)
        tiSuba.innerHTML = dm.liveCams.suba.srcName
        tiSuba.classList.add("animate-textfade")
        dm.prevLiveCams.suba.srcID = dm.liveCams.suba.srcID
        dm.prevLiveCams.suba.srcType = dm.liveCams.suba.srcType
        dm.prevLiveCams.suba.srcUrl = dm.liveCams.suba.srcUrl
        setTimeout(()=>tiSuba.classList.remove("animate-textfade"),8000)
        return true
    }
    return false
}

async function updateSubb(isMuted=true) {
    //initiate if new
    if(playerSubb == null) {
        const optionsSubb = {
            controls: false,
            autoplay: true,
            preload: "auto",
            fluid: true,
            loadingSpinner: false,
            muted: true,
            aspectRatio: "16:9",
            techOrder: ["html5", "youtube"]
        }
        playerSubb = videojs("vt-subb", optionsSubb, function onPlayerReady() {
            this.on('ended', function() {
                this.play();
            });
            this.src({ 
                type: dm.liveCams.subb.srcType,
                src: dm.liveCams.subb.srcUrl 
            });
            console.log("Subb playing ", dm.liveCams.subb.srcID)
            dm.prevLiveCams.subb.srcID = dm.liveCams.subb.srcID
            dm.prevLiveCams.subb.srcType = dm.liveCams.subb.srcType
            dm.prevLiveCams.subb.srcUrl = dm.liveCams.subb.srcUrl
            this.play();
        }); 
    //Otherwise just change source if its different
    } else if(dm.liveCams.subb.srcID != dm.prevLiveCams.subb.srcID) {
        playerSubb.src({ 
            type: dm.liveCams.subb.srcType,
            src: dm.liveCams.subb.srcUrl 
        })
        playerSubb.muted(isMuted)
        playerSubb.play()
        console.log("Subb playing ", dm.liveCams.subb.srcID)
        tiSubb.innerHTML = dm.liveCams.subb.srcName
        tiSubb.classList.add("animate-textfade")
        dm.prevLiveCams.subb.srcID = dm.liveCams.subb.srcID
        dm.prevLiveCams.subb.srcType = dm.liveCams.subb.srcType
        dm.prevLiveCams.subb.srcUrl = dm.liveCams.subb.srcUrl
        setTimeout(()=>tiSubb.classList.remove("animate-textfade"),8000)
        return true
    }
    return false
}

async function updateSubc(isMuted=true) {
    //initiate if new
    if(playerSubc == null) {
        const optionsSubc = {
            autoplay: true,
            preload: "auto",
            fluid: true,
            loadingSpinner: false,
            muted: true,
            aspectRatio: "16:9",
            techOrder: ["html5", "youtube"]
        }
        playerSubc = videojs("vt-subc", optionsSubc, function onPlayerReady() {
            this.on('ended', function() {
                this.play();
            });
            this.src({ 
                type: dm.liveCams.subc.srcType,
                src: dm.liveCams.subc.srcUrl 
            });
            console.log("Subc playing ", dm.liveCams.subc.srcID)
            dm.prevLiveCams.subc.srcID = dm.liveCams.subc.srcID
            dm.prevLiveCams.subc.srcType = dm.liveCams.subc.srcType
            dm.prevLiveCams.subc.srcUrl = dm.liveCams.subc.srcUrl
            this.play();
        }); 
    //Otherwise just change source if its different
    } else if(dm.liveCams.subc.srcID != dm.prevLiveCams.subc.srcID) {
        playerSubc.src({ 
            type: dm.liveCams.subc.srcType,
            src: dm.liveCams.subc.srcUrl 
        })
        playerSubc.muted(isMuted)
        playerSubc.play()
        console.log("Subc playing ", dm.liveCams.subc.srcID)
        tiSubc.innerHTML = dm.liveCams.subc.srcName
        tiSubc.classList.add("animate-textfade")
        dm.prevLiveCams.subc.srcID = dm.liveCams.subc.srcID
        dm.prevLiveCams.subc.srcType = dm.liveCams.subc.srcType
        dm.prevLiveCams.subc.srcUrl = dm.liveCams.subc.srcUrl
        setTimeout(()=>tiSubc.classList.remove("animate-textfade"),8000)
        return true
    }
    return false
}


async function  playPromo() {
    dm.prevLiveCams.subc.srcID = dm.nextPromo.srcID
    dm.prevLiveCams.subc.srcType = dm.nextPromo.srcType
    dm.prevLiveCams.subc.srcUrl = dm.nextPromo.srcUrl
    playerSubc.src({
        type: dm.nextPromo.srcType,
        src: dm.nextPromo.srcUrl 
    })
    playerSubc.play()
    let len = Math.floor(dm.nextPromo.srcDuration * 1000)
    if(isNaN(len)) { len = 15000 }
    setInterval( () => { 
        updateSubc(true)
    }, len)

}

function updateScreens() {
    updatePrim(dm.screenMutes.prim)
    updateSuba(dm.screenMutes.suba)
    updateSubb(dm.screenMutes.subb)
    updateSubc(dm.screenMutes.subc)
}

function outputNews() {
    //News section
    if(dm.newsArr.lenght>1) {
        animateCSS('#newstext', 'fadeIn');
    }
    news.innerHTML = dm.newsArr[dm.newsKey].text;
  }
  

  
/**
 *         Run the app here 
 */
initApp()
  